import React from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import Testimony from "./Testimony";

const testimonials: { name: string; comment: string }[] = [
    {
        name: "Valentine",
        comment:
            "I have social anxiety, I used to avoid messages for days (sometimes weeks or months) before checking them, because i had to wait until I was mentally ready and also didn't want to leave the person on seen. With this app, I am able to see their messages, not have fear about what they might have said ...",
    },
    {
        name: "Stephanie",
        comment:
            "Works as it says it will which can be pretty rare when it comes to apps. I only use it for Facebook Messenger so I can't attest to how it works for the other apps. This app has saved me a few times already from getting involved in things I didn't want to and would have had they known I had seen the ...",
    },
    {
        name: "Neil",
        comment:
            "This app does what it says it does. it is not fancy with alot of flare. instead, it is straightforward, simple to use, and functional. I can read my mesaages from social media and SMS. I can also listen to audio messages. photos and video clips sent to me through social media (WhatsApp, Facebook Messenger. Instagram Messenger, etc.) I'm very satisfied with this app.",
    },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export default function Testimonials() {
    return (
        <Row style={{ padding: "40px 10px", backgroundColor: "whitesmoke" }}>
            <Col style={{ textAlign: "center" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginBottom: 30,
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 style={{ display: "inline-block" }}>
                            People{" "}
                            <span role="img" aria-label="cool smiley">
                                ❤️
                            </span>{" "}
                            being Unseen!
                        </h2>
                    </div>
                </div>

                <div style={{ padding: "0 20px", marginBottom: 30 }}>
                    <Slider
                        {...settings}
                        beforeChange={(current, next) => {
                            console.log("will go to next slide");
                        }}
                    >
                        {testimonials.map((t, i) => (
                            <Testimony key={i} {...t} />
                        ))}
                    </Slider>
                </div>
            </Col>
        </Row>
    );
}
