import React, { Fragment } from "react";

interface Props {
    name: string;
    comment: string;
}

export default function Testimony(props: Props) {
    const { name, comment } = props;

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                    }}
                >
                    <h6 style={{ marginBottom: 0, marginRight: 8 }}>{name}</h6>
                    <FiveStars />
                </div>

                <p
                    className="px-1 px-sm-2 "
                    style={{
                        fontFamily: "Lato",
                        maxWidth: 900,
                    }}
                >
                    {comment}
                </p>
            </div>
        </div>
    );
}

const FiveStars = () => (
    <Fragment>
        <span role="img" aria-label="cool smiley">
            ⭐
        </span>
        <span role="img" aria-label="cool smiley">
            ⭐
        </span>
        <span role="img" aria-label="cool smiley">
            ⭐
        </span>
        <span role="img" aria-label="cool smiley">
            ⭐
        </span>
        <span role="img" aria-label="cool smiley">
            ⭐
        </span>
    </Fragment>
);
