import React from "react";
import "./Footer.css";
import { Row, Col } from "react-bootstrap";
import logofooter from "../assets/logo-footer.svg";

export default function Footer() {
    return (
        <Row className="py-4" style={{ backgroundColor: "#111A4B" }}>
            <Col style={{ textAlign: "center" }}>
                <div className="footer">
                    <span>Email: contact@nolastseen.com</span>
                    <span>
                        Google Play:{" "}
                        <a href="https://bit.ly/3jx1A9c">
                            https://bit.ly/3jx1A9c
                        </a>
                    </span>
                    <span>Privacy Policy</span>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginTop: 20,
                        }}
                    >
                        <img src={logofooter} alt="" />
                        <span
                            style={{
                                marginTop: 3,
                                fontFamily: "Lato",
                                fontSize: 10,
                                fontWeight: 400,
                            }}
                        >
                            Cappsule © 2015 - 2020
                        </span>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
