import React, { Fragment } from "react";
import "./Screenshots.css";
import { Row, Col } from "react-bootstrap";
import Slider, { Settings } from "react-slick";
import screenshot1 from "../assets/screenshot-1@3x.png";
import screenshot2 from "../assets/screenshot-2@3x.png";
import screenshot3 from "../assets/screenshot-3@3x.png";

const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    centerMode: true,
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 1,
                centerMode: false,
            },
        },
    ],
};

const screenshots = [
    {
        imgSrc: screenshot2,
        legend:
            "Enjoy reading your chats while offline & incognito. Try it and you'll love it!",
    },
    {
        imgSrc: screenshot1,
        legend: `Read your messages without the "seen" notice. Works with all popular chat apps`,
    },
    {
        imgSrc: screenshot3,
        legend: "You can also check your photos, videos and audio files",
    },
];

export default function Screenshots() {
    return (
        <Fragment>
            <Row>
                <Col style={{ textAlign: "center", position: "relative" }}>
                    <div className="custom-shape-divider-bottom-1603446802">
                        <svg
                            width="100%"
                            height="600px"
                            viewBox="0 0 1200 420"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                        >
                            <g
                                id="Artboard"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <path
                                    d="M0,75.4337822 C0,75.4337822 95.107,8.69779795 231.224,0.558799868 C317.787,-4.61619891 428.279,27.4977935 473.748,42.25879 L475.106,42.7007899 C575.462,15.5967963 693.192,-0.0002 819.093,-0.0002 C960.774,-0.0002 1092.109,19.7517953 1200,53.4167874 L1200,420 L0,420 L0,75.4337822 Z"
                                    id="Fill-1"
                                    fill="white"
                                ></path>
                            </g>
                        </svg>
                    </div>

                    <Slider
                        {...settings}
                        arrows={false}
                        dotsClass="slick-dots top-dots"
                    >
                        {screenshots.map((s, i) => (
                            <div key={i}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        paddingBottom: 40,
                                    }}
                                >
                                    <img src={s.imgSrc} alt="" height={628} />

                                    <h5
                                        style={{
                                            marginTop: 70,
                                            marginBottom: 20,
                                            maxWidth: 300,
                                        }}
                                    >
                                        {s.legend}
                                    </h5>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Col>
            </Row>
        </Fragment>
    );
}
