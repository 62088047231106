import React from "react";
import { Row, Col } from "react-bootstrap";
import logo from "../logo.svg";

export default function Header() {
    return (
        <header>
            <Row style={{ marginBottom: 60 }}>
                <Col>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: 20,
                            justifyContent: "center",
                        }}
                    >
                        <img src={logo} className="App-logo" alt="logo" />

                        <h1 style={{ color: "whitesmoke" }}>Unseen</h1>
                    </div>
                </Col>
            </Row>
        </header>
    );
}
