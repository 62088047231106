import React from "react";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./components/Header";
import Screenshots from "./components/Screenshots";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";

function App() {
    return (
        <Container fluid>
            <Header />

            <Row style={{ marginBottom: 20 }}>
                <Col>
                    <h1 style={{ textAlign: "center", color: "whitesmoke" }}>
                        No more "Seen" notice when reading your chats{" "}
                        <span role="img" aria-label="cool smiley">
                            😎
                        </span>
                    </h1>
                </Col>
            </Row>

            <Row style={{ marginBottom: 20 }}>
                <Col style={{ textAlign: "center" }}>
                    <a href="https://play.google.com/store/apps/details?id=com.tda.unseen&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            height={120}
                        />
                    </a>
                </Col>
            </Row>

            <Screenshots />

            <Testimonials />

            <Footer />
        </Container>
    );
}

export default App;
